import React from 'react'

export default function Button({ onClick, label, style, disabled, icon, variant }) {

    if (variant === "primary" || !variant)
        return (
            <button className='button' disabled={disabled} onClick={onClick} style={{ marginBottom: '15px', ...style, justifyContent: icon ? 'space-between' : 'center', paddingRight: '15px', paddingLeft: '15px' }}>
                <p>{label}</p>
                {icon}
            </button>
        )
    else if (variant === "border") {
        return (
            <button className='button' disabled={disabled} onClick={onClick} style={{ marginBottom: '15px', ...style, justifyContent: icon ? 'space-between' : 'center', paddingRight: '15px', paddingLeft: '15px',backgroundColor:'transparent',border: '1px solid #DA3831' }}>
                <p style={{ color: '#DA3831' }} >{label}</p>
                {icon}
            </button>
        )
    }
}
