import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { IoAddCircleOutline, IoClose } from "react-icons/io5";
import Button from '../components/Button';
import Services from '../services/Services';
import { Box, Modal, CircularProgress } from '@mui/material';

export default function CompanyCampaigns() {
  const { token, companyID } = useParams();
  const location = useLocation();
  const { state } = location;
  const { company } = state;
  const navigate = useNavigate();

  const [companyCampaigns, setCompanyCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const handleCampaignClick = (campaignID) => {
    navigate(`/${token}/${companyID}/${campaignID}`);
  };

  const getCompanyCampaigns = () => {
    Services.GetBolParaCompanyCampaigns(token, companyID)
      .then((res) => {
        if (res.data.status) {
          const campaigns = res.data.result; // Kampanyaları alıyoruz
          const today = Math.floor(Date.now() / 1000); // Bugünün UNIX zaman damgası

          // Start ve end tarihleri arasında olan kampanyaları filtrele
          const filteredCampaigns = campaigns.filter(campaign => {
            const startDate = parseInt(campaign.start);
            const endDate = parseInt(campaign.end);
            return today >= startDate && today <= endDate;
          });

          setCompanyCampaigns(filteredCampaigns);
        } else {
          setCompanyCampaigns([]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCompanyCampaigns();
  }, []);

  // Modal içerik stil ayarları: genişlik %70, konumlandırma için relative eklenmiştir.
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: 24,
    position: 'relative',
  };

  return (
    <body>
      <div className='main'>
        {isLoading ? (
          <div style={{ marginTop: '35%' }}>
            <CircularProgress />
          </div>
        ) : (
          <div style={{height:'100vh'}}>
            <img
              src={company.logo}
              alt={`${company.title} logo`}
              style={{
                marginTop: '15%',
                maxWidth: '100%',
                height: 'auto',
                objectFit: 'contain',
              }}
            />

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'row',
                alignSelf: 'flex-start',
              }}
            >
              <p style={{ color: '#494D59', fontWeight: '700', fontSize: '18px' }}>
                {company.title} Paket Satışı Nasıl Yapılır?
              </p>
            </div>

            {/* Modal'ı açmak için tıklanabilir yazı */}
            <p
              onClick={() => setOpenModal(true)}
              style={{
                color: '#64748B',
                fontWeight: '400',
                fontSize: '12px',
                cursor: 'pointer',
                textDecoration: 'underline',
                alignSelf: 'flex-start',
                marginTop: '10px',
              }}
            >
              Detayları görmek için tıklayın
            </p>

            {/* Kampanya butonları */}
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%',height:'40%',overflowY:'auto' }}>
              {companyCampaigns.map((campaign) => (
                <Button
                  key={campaign.campaignID}
                  style={{
                    marginTop: '25px',
                    width: '100%',           // Butonun genişliğini kapsayıcı ile eşitler
                    boxSizing: 'border-box'  // Padding ve border genişliği de dahil edilir
                  }}
                  onClick={() => handleCampaignClick(campaign.campaignID)}
                  label={campaign.title}
                  icon={<IoAddCircleOutline color='white' size={24} />}
                />
              ))}
            </div>


            {/* Modal içeriği */}
            <Modal
              open={openModal}
              onClose={() => setOpenModal(false)}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box sx={modalStyle}>
                {/* Sağ üstte "X" butonu */}
                <button
                  onClick={() => setOpenModal(false)}
                  style={{
                    position: 'absolute',
                    top: '8px',
                    right: '8px',
                    background: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '1.5rem',
                    padding: 0,
                  }}
                  aria-label="Kapat"
                >
                  <IoClose />
                </button>
                <ol
                  style={{
                    color: '#64748B',
                    fontWeight: '400',
                    fontSize: '12px',
                    paddingLeft: '20px',
                    marginTop: '40px', // X butonunun altında kalması için boşluk eklendi
                  }}
                >
                  <li>
                    {company.title} Paket Seçin: Müşterilerinize sunmak için mevcut {company.title} paketlerinden birini seçin.
                  </li>
                  <li>
                    Paket Detaylarını Paylaşın: Seçtiğiniz paketlerin içeriklerini ve fiyatlarını müşterinize detaylı bir şekilde aktarın.
                  </li>
                  <li>
                    Satışa Yönlendirin: Satışı tamamlamak için "QR Kodu Görüntüle" butonuna basın ve müşteriden kendi telefonundan QR kodunu okutup gerekli bilgileri tamamlamasını isteyin. Ardından "Hemen Başvur" butonuna basmasını söyleyin.
                  </li>
                  <li>
                    Satışın Tamamlanması: Müşterinize, 48 saat içinde {company.title} merkezinin işlemleri tamamlamak için kendisini arayacağı bilgisini iletin.
                  </li>
                  <li>
                    Satışın Onaylanması: Eğer ilgili ekiplerinin araması sonucu satış olumlu olursa, kazanç sağlarsınız.
                  </li>
                  <li>
                    Komisyon Kazanın: Her satıştan kazandığınız komisyonu Easy Point uygulamasında takip edebilirsiniz.
                  </li>
                </ol>
              </Box>
            </Modal>
          </div>
        )}
      </div>
    </body>
  );
}
