import React, { useState } from 'react';
import './SearchInput.css';
import { FiSearch } from 'react-icons/fi';

export default function SearchInput({ onSearch, placeholder }) {
    const [searchTerm, setSearchTerm] = useState('');

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
        onSearch(event.target.value);
    };

    return (
        <div className="search-input-container">
            <FiSearch className="search-icon" />
            <input
                type="text"
                placeholder={placeholder}
                className="search-input"
                value={searchTerm}
                onChange={handleChange}
            />
        </div>
    );
}
