import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../components/Button';
import Modal from '../components/Modal';
import { RxCross1 } from "react-icons/rx";
import { Carousel } from 'react-responsive-carousel'; // Carousel import
import './PackageInfo.css';
import Services from '../services/Services';
import { CircularProgress } from '@mui/material';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Carousel styles

export default function PackageInfo() {
    const { token, campaignID } = useParams();

    const [isModalOpen, setIsModalOpen] = useState(false); // QR kod modalı
    const [campaign, setCampaign] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [agreements, setAgreements] = useState([]);

    // Yeni durumlar: şartları kabul etme
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
    const [currentAgreementIndex, setCurrentAgreementIndex] = useState(0);

    const getCampaign = () => {
        Services.GetBolParaCampaign(token, campaignID)
            .then((res) => {
                if (res.data.status) {
                    setCampaign(res.data.result);
                    getCampaignAgreements();
                } else {
                    setCampaign();
                    setIsLoading(false);
                }
            })
            .catch((err) => console.log(err));
    };

    const getCampaignAgreements = () => {
        Services.GetBolParaAgreement(token, campaignID)
            .then((res) => {
                if (res.data.status) {
                    setAgreements(res.data.result);
                    console.log(res.data.result);
                } else {
                    setAgreements([]);
                }
                setIsLoading(false);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getCampaign();
    }, []);

    // Agreement başlıklarını biçimlendirme:
    const formattedTitles = agreements.length > 0 ? (
        agreements.length === 1 ? agreements[0].title :
            agreements.length === 2 ? `${agreements[0].title} ve ${agreements[1].title}` :
                agreements.slice(0, -2).map(item => item.title).join(", ") + ", " +
                `${agreements[agreements.length - 2].title} ve ${agreements[agreements.length - 1].title}`
    ) : "";

    return (
        <body>
            <div className='main' style={{ width: '100%' }}>
                {isLoading ? (
                    <div style={{ marginTop: '35%' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        {campaign && (
                            <>
                                {campaign.images && (
                                    <Carousel
                                        showStatus={false}
                                        showArrows={false}
                                        autoPlay={true}
                                        interval={3000}
                                        infiniteLoop={true}
                                        showThumbs={false}
                                        showIndicators={false}
                                        className="custom-carousel">
                                        {campaign.images.map((image, index) => (
                                            <div key={index} className="carousel-image-container">
                                                <img src={image} alt={`Image ${index}`} className="carousel-image" />
                                            </div>
                                        ))}
                                    </Carousel>
                                )}

                                {/* Checkbox ve agreement başlıkları */}
                                {agreements.length > 0 &&
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '50px', marginBottom: '10px', width: '90%' }}>
                                        <input
                                            type="checkbox"
                                            className="custom-checkbox"
                                            checked={termsAccepted}
                                            onClick={() => {
                                                if (!termsAccepted) {
                                                    setIsTermsModalOpen(true);
                                                    setCurrentAgreementIndex(0);
                                                } else {
                                                    setTermsAccepted(false);
                                                    setCurrentAgreementIndex(0);
                                                }
                                            }}
                                        />

                                        <label style={{ marginLeft: '8px' }}>{formattedTitles}</label>
                                    </div>
                                }


                                <Button
                                    onClick={() => setIsModalOpen(true)}
                                    style={{ marginTop: '5%', width: '236px' }}
                                    variant={'primary'}
                                    label={'QR Kodu Görüntüle'}
                                    disabled={!termsAccepted && agreements.length > 0} // Şartlar kabul edilmediği sürece disabled
                                />

                                <div style={{ padding: '20px' }}>
                                    <div style={{
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        flexDirection: 'row',
                                        display: 'flex',
                                        alignSelf: 'flex-start'
                                    }}>
                                        <p style={{ color: '#494D59', fontWeight: '700', fontSize: '18px' }}>
                                            {campaign.title} Paketi
                                        </p>
                                    </div>
                                    <div style={{ alignSelf: 'flex-start', marginBottom: '50%' }}>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: campaign.description }}
                                            style={{ whiteSpace: 'pre-wrap' }}
                                        />
                                    </div>
                                </div>

                                {/* QR Kodu gösteren modal */}
                                <Modal visible={isModalOpen} style={{ height: '290px', width: '290px' }}>
                                    <div style={{
                                        width: '100%',
                                        height: '100%',
                                        padding: '15px',
                                        alignItems: 'center',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <div style={{
                                            width: '100%',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                            display: 'flex'
                                        }}>
                                            <RxCross1 onClick={() => setIsModalOpen(false)} />
                                        </div>
                                        <div style={{
                                            backgroundColor: 'black',
                                            marginTop: '10px',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            width: '169px',
                                            height: '169px',
                                            borderRadius: '8px'
                                        }}>
                                            <img
                                                style={{ width: '147px' }}
                                                src={`https://qr.easypointapi.com/?chl=https://stage-bolpara.easypoint.com.tr?token=${token}:${campaignID}`}
                                                alt='QR Code'
                                            />
                                        </div>
                                        <text style={{
                                            color: '#64748B',
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            marginTop: '14px'
                                        }}>QR Kodu</text>
                                        <text style={{
                                            color: '#64748B',
                                            fontWeight: '400',
                                            fontSize: '11px',
                                            marginTop: '8px',
                                            textAlign: 'center'
                                        }}>
                                            Müşterinizin başvuru formuna ulaşması için QR Kodu okutmasını isteyiniz.
                                        </text>
                                    </div>
                                </Modal>

                                {isTermsModalOpen && (
                                    <Modal visible={isTermsModalOpen} style={{ width: '290px', padding: '25px', marginTop: '55%' }}>
                                        <div style={{
                                            width: '100%',
                                            padding: '15px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}>
                                            <div style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'flex-end'
                                            }}>
                                                <RxCross1 onClick={() => {
                                                    setIsTermsModalOpen(false);
                                                    setCurrentAgreementIndex(0);
                                                }} />
                                            </div>
                                            <div style={{
                                                flex: 1,
                                                marginTop: '10px',
                                                overflowY: 'auto',
                                                width: '100%'
                                            }}>
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: agreements[currentAgreementIndex]?.content }}
                                                    style={{ whiteSpace: 'pre-wrap' }}
                                                />
                                            </div>
                                            <Button
                                                onClick={() => {
                                                    if (currentAgreementIndex < agreements.length - 1) {
                                                        setCurrentAgreementIndex(currentAgreementIndex + 1);
                                                    } else {
                                                        // Son agreement kabul edildiğinde modal kapatılır ve checkbox true olur
                                                        setTermsAccepted(true);
                                                        setIsTermsModalOpen(false);
                                                    }
                                                }}
                                                style={{ marginTop: '10px', width: '100%' }}
                                                variant={'primary'}
                                                label={'Koşulları Kabul Ediyorum'}
                                            />
                                        </div>
                                    </Modal>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </body>
    );
}
