import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoArrowBackOutline } from "react-icons/io5";

function Layout({ children }) {
    const navigate = useNavigate();
    const location = useLocation();

    const pathParts = location.pathname.split('/').filter(Boolean);
    const isMainPage = pathParts.length === 1;

    const handleBackClick = () => {
        navigate(-1); // Go back to the previous page
    };

    return (
        <div>
            {/* Eğer ana sayfada değilsek geri butonunu göster */}
            {!isMainPage && (
                <div onClick={handleBackClick} style={styles.backButton}>
                    <IoArrowBackOutline />
                </div>
            )}
            <main>{children}</main>
        </div>
    );
}


const styles = {
    backButton: {
        position: 'fixed',
        top: '25px',
        right: '10px',
        zIndex: 998,
        backgroundColor: '#fff',
        color: 'black',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '32px',
        height: '32px',
        boxShadow: '0px 1px 2px 0px #1018280D',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
};

export default Layout;
